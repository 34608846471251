import React from 'react';
import { Container } from 'react-bootstrap';

const TermsConditions = () => {
    return (
        <Container className="my-5">
            <h1>Terms & Conditions</h1>
            <p>
                Welcome to our website. By accessing or using our services, you agree to be bound by these Terms and Conditions. Please read them carefully before using our site.
            </p>
            <h2>Use of Services</h2>
            <p>
                You agree to use our services only for lawful purposes and in a way that does not infringe the rights of others or restrict their use and enjoyment of the services.
            </p>
            <h2>Account Responsibility</h2>
            <p>
                You are responsible for maintaining the confidentiality of your account details and for all activities that occur under your account.
            </p>
            <h2>Limitation of Liability</h2>
            <p>
                We are not liable for any damages that may arise from your use of our services. Your use of the site and services is at your own risk.
            </p>
            <p></p>
        </Container>
    );
};

export default TermsConditions;

import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicy = () => {
    return (
        <Container className="my-5">
            <h1>Privacy Policy</h1>
            <p>
                This Privacy Policy explains how we collect, use, and protect your personal information. By using our website or services, you agree to the collection and use of information in accordance with this policy.
            </p>
            <h2>Information We Collect</h2>
            <p>
                We collect information that you provide to us directly, such as when you create an account, make a purchase, or contact us for support. This may include your name, email address, payment details, and other personal information.
            </p>
            <h2>How We Use Your Information</h2>
            <p>
                We use your information to provide, maintain, and improve our services, to process transactions, and to communicate with you about your account and our services.
            </p>
            <h2>Security</h2>
            <p>
                We take reasonable measures to protect your information from unauthorized access, but no method of transmission over the internet is 100% secure.
            </p>
            
        </Container>
    );
};

export default PrivacyPolicy;

import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const RegistrationForm = () => {
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        gender: '',
        language: '',
        acceptTerms: false
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUserData({
            ...userData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userData.acceptTerms) {
            alert('You must agree to the Payment Terms and Conditions and Privacy Policy to register.');
            return;
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`, { userData });
            alert("Registration Done");
            setUserData({
                name: '',
                email: '',
                phone: '',
                address: '',
                gender: '',
                language: '',
                acceptTerms: false
            });
            navigate('/course');
        } catch (error) {
            console.error(error);
            alert('Registration failed');
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
                <Col md={6} className="mx-auto">
                    <div className="p-4 rounded bg-light shadow">
                        <h2 className="text-center mb-4">Register Now</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={userData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={userData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formPhone" className="mb-3">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="phone"
                                    placeholder="Enter your phone number"
                                    value={userData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formAddress" className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    placeholder="Enter your address"
                                    value={userData.address}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formGender" className="mb-3">
                                <Form.Label >Gender</Form.Label>
                                <br/>
                                <Form.Check
                                    inline
                                    label="Male"
                                    name="gender"
                                    type="radio"
                                    value="male"
                                    checked={userData.gender === 'male'}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    inline
                                    label="Female"
                                    name="gender"
                                    type="radio"
                                    value="female"
                                    checked={userData.gender === 'female'}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formLanguage" className="mb-3">
                                <Form.Label >Language</Form.Label>
                                <br/>
                                <Form.Check
                                    inline
                                    label="Marathi"
                                    name="language"
                                    type="radio"
                                    value="Marathi"
                                    checked={userData.language === 'Marathi'}
                                    onChange={handleChange}
                                    
                                />
                                <Form.Check
                                    inline
                                    label="Hindi"
                                    name="language"
                                    type="radio"
                                    value="Hindi"
                                    checked={userData.language === 'Hindi'}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            {/* Checkbox for accepting Terms and Conditions */}
                            <Form.Group controlId="formTerms" className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    name="acceptTerms"
                                    label={
                                        <>
                                            I agree to the{' '}
                                            <Link to="/refund-cancellation" target="_blank" rel="noopener noreferrer">
                                                Payment Terms and Conditions
                                            </Link>{' '}
                                            and{' '}
                                            <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                Privacy Policy
                                            </Link>.
                                        </>
                                    }
                                    checked={userData.acceptTerms}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="w-100">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default RegistrationForm;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaYoutube, FaTelegramPlane, FaGraduationCap, FaTrophy, FaQuestionCircle, FaCodeBranch, FaBriefcase, FaComments } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PromoteCodingCulture = () => {
  const sectionStyle = {
    background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)',
    color: 'white',
    borderRadius: '15px',
    padding: '30px',
    marginBottom: '30px'
  };

  const textStyle = {
    fontWeight: 'bold',
    fontSize: '1.1rem'
  };

  const iconTextStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  };

  const iconStyle = {
    marginRight: '15px'
  };

  const buttonStyle = {
    fontSize: '1rem',
    padding: '10px 20px',
   
  };

  return (
    <section className='mx-lg-5' style={sectionStyle}>
      <Container>
        <Row>
          <Col md={6} className='my-auto'>
            <h2>We are on a mission </h2>
            <p style={textStyle}>
            We are on a mission to nurture and empower the next generation of tech innovators in India. Our goal is to provide quality education, hands-on experience, and valuable opportunities in programming, web development, and emerging technologies. By bridging the gap between learning and industry demands, we aim to create a thriving community of skilled techies ready to shape the future of technology.
            </p>
            <div className="d-flex mt-4 mb-sm-3">
             <Link to="/course" className='btn btn-primary w-50'>Join Now</Link>
            
            </div>
          </Col>
          <Col md={6}>
           <img src="https://img.freepik.com/free-vector/pair-programming-concept-illustration_114360-3941.jpg?ga=GA1.2.918187449.1718384370&semt=ais_hybrid" alt='Arrival Solution - Training' className='img-fuild' width="100%"/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PromoteCodingCulture;

import React from "react";

import CourseCard from "./CourseCard";
import SuccessSection from "./SuccessSection";
import PromoteCodingCulture from "./PromoteCodingCulture";
import Connect from "./Connect";

const Landing = () => {
    return (
      <div>
          <div class="container col-xxl-8 px-4 py-5">
        <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div class="col-10 col-sm-8 col-lg-6">
            <img src="https://cdn.pixabay.com/photo/2024/05/01/17/56/student-8732859_1280.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
          </div>
          <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Boost your career
            with us!</h1>
            <p class="lead">
            We offer practical training and internships tailored for Marathi and Hindi speakers, designed to build your career in the tech industry. Our courses are specially curated for you, providing hands-on experience and real-world knowledge to prepare you for a successful career.
            </p>
            {/* <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              <button type="button" class="btn btn-primary btn-lg px-4 me-md-2">Primary</button>
             
            </div> */}
          </div>
        </div>
      </div>

      <SuccessSection/>
      <PromoteCodingCulture/>
      <Connect/>
      </div>
    )
}

export default Landing
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [alert, setAlert] = useState({
        show: false,
        message: '',
        variant: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, formData);
            setAlert({
                show: true,
                message: response.data.message,
                variant: 'success',
            });
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
            });
        } catch (error) {
            setAlert({
                show: true,
                message: 'Failed to submit contact request',
                variant: 'danger',
            });
        }
    };

    return (
        <Container className="my-5">
            <Row className="">

            <Col md={5} className='me-2 shadow-lg p-3 mb-5 bg-white rounded"'>
            <h1 className='mb-5'>Connect Us</h1>
            <hr></hr>
            <h5>Office Time</h5>
            <p>Mon - Fri - 10:00 am to 06:00 pm</p>
            <hr/>
               <h5> Our Address : 
             </h5>
             <p>  Khalap , Tal - Deola, Dist - Nashik, 423301</p>
             <hr/>
             <h5>Write Us</h5>
             <p> arrivalsoutions@gmail.com
             </p>
             <hr/>
             <h5>WhatsApp</h5>
             <p> +91 9322142526</p>
            </Col>

                <Col md={5} className='ms-2 shadow p-3 mb-5 bg-secondary rounded'>
                    <h2>Contact Us</h2>
                    {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="Enter your phone number"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Enter your message"
                                rows={4}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactForm;

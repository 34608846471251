import React from 'react';
import { Container } from 'react-bootstrap';

const RefundCancellation = () => {
    return (
        <Container className="my-5">
            <section class="policy-section bg-light p-4">
  <div class="container">
    <h2>Cancellation & Refund Policy</h2>
    <p>
      We strive to provide the best services to our users. Please note that once a purchase is made or a service is availed, it is considered final. 
      As a result, we do not offer any refund on purchases or subscriptions under any circumstances. 
    </p>
    <p>
      If you wish to cancel a subscription or service, you may do so at any time, but no refunds will be provided for any payments already made.
    </p>
    <h4>Key Points:</h4>
    <ul>
      <li>No refunds will be provided for any product, service, or subscription purchased through our platform.</li>
      <li>Cancellations can be made, but they will not result in a refund.</li>
      <li>We encourage you to carefully evaluate the service before making a purchase.</li>
    </ul>
    <p>
      If you have any questions about this policy, please feel free to contact us at <a href="mailto:support@yourwebsite.com">support@arrivalsolutions.in</a>.
    </p>
  </div>
</section>

        </Container>
    );
};

export default RefundCancellation;

import axios from 'axios';
import React, { useState } from 'react'

const Callback = () => {
    const [name, setName] = useState();
    const [email,setEmail] = useState();
    const [phone, setPhone] = useState();
    const [doubt, setDoubt] = useState();

    const handleSubmit = async(e) =>{
        e.preventDefault();

        try{
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/callback`,{
                name,
                email,
                phone,
                doubt
            });
            alert("Your Request Submitted Successfully");
            setName('')
            setEmail('')
            setPhone('')
            setDoubt('')
        }catch(error){
            console.log("Error : ", error)
        }
    }

    return (
        <div className='container'>
            <div className=''>
                <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                        <label className='form-label'>Name</label>
                        <input className='form-control'
                        value={name}
                        onChange={(e)=>setName(e.target.value)} required />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label'>Email</label>
                        <input className='form-control' 
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        required />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label'>Phone</label>
                        <input className='form-control' 
                          value={phone}
                          onChange={(e)=>setPhone(e.target.value)}
                        required />
                    </div>
                    <div className='mb-3'>
                        <label className='form-label'>Doubt</label>
                        <textarea cols={5} 
                        className='form-control'
                        value={doubt}
                        onChange={(e)=>setDoubt(e.target.value)}
                        required></textarea>
                    </div>
                    <div className='mb-3'>
                        <button type='submit' className='btn btn-primary'>Submit</button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default Callback;
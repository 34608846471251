import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Callback from './Callback';


const CourseCard = () => {
    return (
        <div className="container my-5">
            <Row>
                <Col md={8}>
                    <Card className="course-card">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h3> Frontend Web Development Training & Internship</h3>
                                    <p>Learn Frontend Web Development using HTML, CSS, JavaScript , React.js & Next.js</p>
                                </div>
                                <div className="course-icon">
                                    <img src="https://images.pexels.com/photos/1181359/pexels-photo-1181359.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Course Icon" />
                                </div>
                            </div>
                            {/* <div className="d-flex align-items-center mb-3">
                                <span className="text-warning">&#9733; &#9733; &#9733; &#9733; &#9734;</span>
                                <span className="ms-2">4.5/5.0, 222 ratings</span>
                            </div> */}
                            {/* <div className="d-flex align-items-center">
                                <img src="https://via.placeholder.com/40x40" alt="Instructor" className="rounded-circle me-2" />
                                <span>Arnav Gupta</span>
                            </div>
                            <p className="mt-3">Course Language: English</p> */}
                        </Card.Body>
                    </Card>

                    {/*  */}
                    <Col md={12}>
                        <Card className="summary-card mt-3 shadow p-3 mb-5 bg-body-tertiary rounded">
                            <Card.Body>
                                <h4>Summary</h4>
                                <p>
                                    Frontend Development involves creating the user interface and user experience of websites and applications using technologies like HTML, CSS, and JavaScript. By leveraging modern frameworks such as React.js and Next.js, developers can build dynamic, responsive, and high-performance web applications. These tools enable efficient development processes, seamless navigation, and interactive features, enhancing the overall user experience.
                                </p>
                                <h5>Highlights</h5>
                                <ul>
                                    <li> Training available in Marathi and Hindi for better understanding and learning.</li>
                                    <li>3 Month duration  </li>
                                    <li>Project-Based Learning Approach</li>
                                    <li>Lessons on Deploying your Application</li>
                                    <li>Basics & Advanced Topics for Interviews</li>
                                    <li>Expert Doubt Support for 6 months </li>
                                    <li> Gain practical experience with an internship opportunity, enhancing your resume and career prospects.</li>
                                </ul>
                                <h5>Pre-requisites</h5>
                                <p>No prior experience required. we start from Scratch</p>
                                <h5>What You'll Learn:</h5>
                                <ul>
                                    <li>Basic web development with HTML, CSS, and JavaScript.</li>
                                    <li><b>Front End:</b> React.js, Next.js</li>
                                    <li><b>Version Control:</b> Git, GitHub,</li>
                                    <li> <b>Deployment: </b> Vercel, Digital Ocean, AWS , Cpanel, Render</li>
                                    <li>Creating dynamic user interfaces with React.js.</li>
                                    <li>Building and deploying web applications</li>

                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Col>
                <Col md={4}>
                    <Card className="mb-4">
                        <Card.Img variant="top" src="https://cdn.pixabay.com/photo/2022/07/08/05/38/freelance-work-7308505_640.png" alt="Video Thumbnail" />

                    </Card>
                    <a href="https://wa.me/919322142526" target="_blank" class="btn btn-info w-100 mb-3">
                    <i class="bi bi-whatsapp me-3"></i>   Chat on WhatsApp
                    </a>

                    <Card>
                        <Card.Body>
                            <h5>Register Now</h5>

                            <p>₹1499 <s className='text-danger'>₹4999</s></p>
                            <Link to="/register" className="btn btn-primary w-100">Register Now</Link>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3'>
                        <Card.Body>
                            <h5>Request To Callback</h5>
                            <Callback/>
                        </Card.Body>
                    </Card>
                </Col>

              
            </Row>
            <Row className="mt-4">

            </Row>
        </div>
    );
};

export default CourseCard;

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import RegistrationForm from './components/RegistrationForm';
import Landing from './components/Landing';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReceiptPage from './components/ReceiptPage';
import Footer from './layout/Footer';

import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import RefundCancellation from './components/RefundCancellation';
import Navbar from './layout/Navbar';
import AboutUs from './components/AboutUs';
import CourseCard from './components/CourseCard';
import ContactForm from './components/ContactForm';
import ScrollToTop from './ScrollToTop';
function App() {
  return (
    <BrowserRouter>
    <Navbar/>
    <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/course" element={<CourseCard/>} />
        <Route path='/contact' element={<ContactForm/>} />
        <Route path='/register' element={<RegistrationForm />} />
        <Route path="/receipt" element={<ReceiptPage />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/refund-cancellation" element={<RefundCancellation />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

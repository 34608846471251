import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import QRCode from 'qrcode.react';

const ReceiptPage = () => {
    const location = useLocation();
    const { name, email, phone, amount, orderId, date } = location.state;

    const downloadReceipt = () => {
        const doc = new jsPDF();
        
        doc.setFontSize(20);
        doc.text("Invoice - Front End Course", 20, 20);
        
        doc.setFontSize(12);
        doc.text(`Name: ${name}`, 20, 40);
        doc.text(`Email: ${email}`, 20, 50);
        doc.text(`Phone: ${phone}`, 20, 60);
        doc.text(`Amount Paid: ₹${amount}`, 20, 70);
        doc.text(`Order ID: ${orderId}`, 20, 80);
        doc.text(`Date: ${date}`, 20, 90);
        doc.text(`Course: Front End Development`, 20, 100);

        // Adding QR Code for verification
        const qrCodeDataUrl = document.getElementById('qrCode').toDataURL();
        doc.addImage(qrCodeDataUrl, 'PNG', 20, 110, 50, 50);

        doc.save('invoice_frontend_course.pdf');
    };

    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <Row className="w-100">
                <Col md={8} className="mx-auto">
                    <Card className="p-4">
                        <Card.Body>
                            <h2 className="text-center mb-4">Invoice - Front End Course</h2>
                            <p><strong>Name:</strong> {name}</p>
                            <p><strong>Email:</strong> {email}</p>
                            <p><strong>Phone:</strong> {phone}</p>
                            <p><strong>Amount Paid:</strong> ₹{amount}</p>
                            <p><strong>Order ID:</strong> {orderId}</p>
                            <p><strong>Date:</strong> {date}</p>
                            <p><strong>Course:</strong> Front End Development</p>
                            <div className="text-center">
                                <QRCode 
                                    id="qrCode"
                                    value={`OrderID: ${orderId}\nAmount: ₹${amount}`}
                                    size={128}
                                    includeMargin={true}
                                />
                            </div>
                            <Button variant="primary" onClick={downloadReceipt} className="mt-4">
                                Download Invoice
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ReceiptPage;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-dark text-light pt-5 pb-4">
            <Container>
                <Row>
                    <Col md={4} sm={12} className="mb-4">
                        <h5>About</h5>
                        <p>
                        We offer practical training and internships tailored for Marathi and Hindi speakers, designed to build your career in the tech industry. Our courses are specially curated for you, providing hands-on experience and real-world knowledge to prepare you for a successful career.
                        </p>
                    </Col>

                    <Col md={4} sm={6} className="mb-4">
                        <h5>Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/" className="text-light text-decoration-none">Home</Link></li>
                            <li><Link to="/course" className="text-light text-decoration-none">Courses</Link></li>
                            <li><Link to="/about-us" className="text-light text-decoration-none">About</Link></li>
                            <li><Link to="/career" className="text-light text-decoration-none">Career</Link></li>
                        </ul>
                    </Col>

                    <Col md={4} sm={6} className="mb-4">
                        <h5>Company</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/privacy-policy" className="text-light text-decoration-none">Privacy Policy</Link></li>
                            <li><Link to="/terms-conditions" className="text-light text-decoration-none">Terms & Conditions</Link></li>
                            <li><Link to="/refund-cancellation" className="text-light text-decoration-none">Refund & Cancellation</Link></li>
                            <li><Link to="/contact" className="text-light text-decoration-none">Contact</Link></li>
                        </ul>
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col className="text-center">
                        <p>&copy; 2024 Your Arival Solution. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;

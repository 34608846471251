import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const AboutUs = () => {
    return (
        <Container className="my-5">
            <Row className="align-items-center">
                <Col md={6} className="mb-4 mb-md-0">
                    <Image 
                        src="https://cdn.pixabay.com/photo/2018/05/09/22/40/career-3386334_640.jpg" // Replace with your image URL
                        alt="About Us" 
                        fluid 
                        className="rounded"
                    />
                </Col>
                <Col md={6}>
                    <h1>About Us</h1>
                    <p>
                        Welcome to  Arrival Solution, a place where learning meets real-world experience. We are dedicated to providing students and professionals with cutting-edge training and hands-on internships to help them excel in their careers.
                    </p>

                    <h2>Our Mission</h2>
                    <p>
                        Our mission is to bridge the gap between education and the workforce by offering high-quality training and internship opportunities. We empower individuals to gain the skills and experience needed to succeed in today’s competitive job market.
                    </p>

                    <h2>Our Vision</h2>
                    <p>
                        Our vision is to create a global platform where aspiring professionals can seamlessly transition from education to employment. We strive to foster a community of skilled professionals who are confident, capable, and ready to contribute to their industries.
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutUs;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaBriefcase, FaRocket, FaUserPlus, FaLaptopCode, FaMoneyBillWave } from 'react-icons/fa';

const SuccessSection = () => {
  return (
    <section className="py-5 text-white bg-dark my-5">
      <Container>
        <h2 className="text-center mb-4">Your Success is Our Success</h2>
        <Row className="mb-5">
          {/* Freshers Section */}
          <Col md={6} className="text-center text-md-start">
            <h3 className="text-warning mb-3">Feature</h3>
            <div className="d-flex flex-column align-items-center align-items-md-start">
              <div className="d-flex align-items-center mb-3">
              <FaLaptopCode size={30} className="me-3 text-light" />
                <p>Hands-on projects and real-world applications to enhance your skills.</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaRocket size={30} className="me-3 text-light" />
                <p>Training and courses designed specifically for Marathi and Hindi speakers.</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaUserPlus size={30} className="me-3 text-light" />
                <p> Receive personalized career support through webinars, personal chats</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaBriefcase size={30} className="me-3 text-light" />
                <p>Gain work experience with our internship programs to boost your resume.
                </p>
              </div>
            </div>
          </Col>

          {/* Laterals Section */}
          <Col md={6} className="text-center text-md-start">
            <h3 className="text-warning mb-3">Program Highlights:</h3>
            <div className="d-flex flex-column align-items-center align-items-md-start">
              <div className="d-flex align-items-center mb-3">
                <FaLaptopCode size={30} className="me-3 text-light" />
                <p> Courses tailored to your pace and level of understanding.</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaRocket size={30} className="me-3 text-light" />
                <p>Every course includes real-world projects to prepare you for the job market.</p>
              </div>
              <div className="d-flex align-items-center mb-3">
              <FaUserPlus size={30} className="me-3 text-light" />
                <p> Get access to mentors and career advisors throughout your learning journey.</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaLaptopCode size={30} className="me-3 text-light" />
                <p> Deployment: Vercel, Digital Ocean, AWS , Cpanel, Render
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SuccessSection;

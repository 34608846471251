import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Connect = () => {
  // Define styles for each social media icon
  const iconStyles = {
    facebook: { color: '#1877F2' },  // Facebook blue
    instagram: { color: '#E4405F' }, // Instagram pink
    linkedin: { color: '#0077B5' },  // LinkedIn blue
    whatsapp: { color: '#25D366' }   // WhatsApp green
  };

  return (
    <Container className="text-center mb-4">
      <div className='text-center bg-dark text-light py-4'>
        <h3 className='mb-4'>Let's Connect</h3>
        <div className="d-flex justify-content-center">
          <a href="https://www.facebook.com/people/Arrival-Solutions/61558098066622/" target=' ' className='me-4' style={iconStyles.facebook}>
            <i className='bi bi-facebook' style={{ fontSize: '2rem' }}></i>
          </a>
          <a href="https://www.instagram.com/arrivalsolutions" target=' ' className='me-4' style={iconStyles.instagram}>
            <i className='bi bi-instagram' style={{ fontSize: '2rem' }}></i>
          </a>
          <a href="https://www.linkedin.com/company/arrivalsolutions" target=' ' className='me-4' style={iconStyles.linkedin}>
            <i className='bi bi-linkedin' style={{ fontSize: '2rem' }}></i>
          </a>
          <a href="https://wa.me/919322142526" target=' ' style={iconStyles.whatsapp}>
            <i className='bi bi-whatsapp' style={{ fontSize: '2rem' }}></i>
          </a>
        </div>
      </div>
    </Container>
  );
};

export default Connect;
